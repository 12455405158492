import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { fetchProductProgramTypes, fetchProducts } from './index';
import { IProduct, IProductProgramType } from './interface';

export const useProductListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery<IPagination<IProduct>, Error>({
        queryKey: [QueryKey.PRODUCTS, query],
        queryFn: ({ signal }) => fetchProducts(query, signal),
        ...options,
    });
};

export const useProductProgramTypesListQuery = (options = {}) => {
    return useQuery<IProductProgramType[], Error>({
        queryKey: [QueryKey.PRODUCT_PROGRAM_TYPES, null],
        queryFn: ({ signal }) => fetchProductProgramTypes(signal),
        ...options,
    });
};
