import React from 'react';
import Typography from 'antd/lib/typography';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Alert from 'antd/lib/alert';
import Modal from 'antd/lib/modal/Modal';
import { IProduct } from '../../domain/product/interface';
import { unlinkProgram } from '../../domain/product';

interface IUnlinkProgramFromProductProps {
    product: IProduct;
    onClose: (isRefreshNeeded?: boolean) => void;
}

export const UnlinkProgramFromProduct: React.FC<IUnlinkProgramFromProductProps> = ({ product, onClose }) => {
    const { notification } = App.useApp();

    const [loading, setLoading] = React.useState<boolean>(false);

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await unlinkProgram(product.salesforce_id);

            onClose(true);
            notification.success({ key: 'unlink-program-success', message: 'Program unlinked successfully!' });
        } catch (error: any) {
            notification.error({ key: 'unlink-program-error', message: error.message || 'Cannot unlink program!' });
        }
        setLoading(false);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            title="Unlink Program"
            open
            destroyOnClose
            onCancel={handleClose}
            footer={[
                <Button key="unlink-program-modal-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="unlink-program-modal-submit" type="primary" loading={loading} onClick={handleConfirm}>
                    Unlink
                </Button>,
            ]}
        >
            <Alert message={`Changes will be applied to product: ${product.name}`} type="warning" style={{ marginBottom: '8px' }} showIcon />
            <Typography.Text>
                Are you sure you want to unlink program <strong>{product.program?.name}</strong>?
            </Typography.Text>
        </Modal>
    );
};
