import { AbilityBuilder, AbilityClass, MatchConditions, PureAbility } from '@casl/ability';
import { Actor } from '../../domain/auth/actor';
import { UserType } from '../../domain/user/interface';

// manage, all - keywords that allows to do every action on every subject (e.g pages)
type Actions =
    | 'create'
    | 'read'
    | 'update'
    | 'update:branding'
    | 'merge'
    | 'delete'
    | 'upload'
    | 'enroll'
    | 'attach'
    | 'filter'
    | 'view-route'
    | 'close-open'
    | 'manage'
    | 'create:batchByProgram'
    | 'connect'
    | 'request';
export type RouteSubject =
    | '/companies'
    | '/sites'
    | '/settlements'
    | '/events'
    | '/programs'
    | '/trigger/price-response'
    | '/trigger/clean-response'
    | '/users'
    | '/settings'
    | '/impact'
    | '/ven-devices'
    | '/ven-devices/overview'
    | '/ven-devices/cloud-ven'
    | '/ven-devices/customer-ven'
    | '/caiso'
    | '/caiso/sibr'
    | '/caiso/drrs'
    | '/'
    | '/reporting/price-response'
    | '/energy-usage-data/summary'
    | '/energy-usage-data/connect'
    | '/energy-usage-data/analytics'
    | '/programs/sites-enrollment'
    | '/nyiso-resources'
    | '/peak-load-forecast'
    | '/admin/data-quality'
    | '/admin/data-quality/by-company'
    | '/admin/data-quality/by-enrollment'
    | '/admin/new-and-closed-sites'
    | '/admin/recently-deleted-objects'
    | '/admin/salesforce-products'
    | '/admin/audit-trail';
type Subjects =
    | RouteSubject
    | 'Site'
    | 'Company'
    | 'Event'
    | 'User'
    | 'Program'
    | 'Settlement'
    | 'Reporting'
    | 'Dashboard'
    | 'PriceResponse'
    | 'CleanResponse'
    | 'Settings'
    | 'ControlProvider'
    | 'UtilityCustomer'
    | 'EnergyCustomer'
    | 'ConnectCustomer'
    | 'ImpactReporting'
    | 'EnergyFile'
    | 'CloudVen'
    | 'CustomerVen'
    | 'CaisoSIBR'
    | 'CaisoDRRS'
    | 'RemoteAccess'
    | 'all'
    | 'carbon-intensity'
    | 'PriceResponseReporting'
    | 'PowerBIReporting'
    | 'LeapApi'
    | 'IntervalDataReporting'
    | 'AsyncJob'
    | 'SanValidationSuggestion'
    | 'NyisoResource'
    | 'PeakLoadForecast'
    | 'EnergyUsage';

export type AppAbilityType = PureAbility<[Actions, Subjects], MatchConditions>;
export const AppAbility = PureAbility as AbilityClass<AppAbilityType>;
const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;

export function buildAbilityFor(user: Actor | null) {
    const { can, cannot, build } = new AbilityBuilder(AppAbility);

    if (!user) {
        return build({ conditionsMatcher: lambdaMatcher });
    }
    const role = user?.user_type;

    switch (role) {
        case UserType.ADMIN:
            can(
                [
                    'create',
                    'delete',
                    'update',
                    'update:branding',
                    'read',
                    'enroll',
                    'attach',
                    'filter',
                    'upload',
                    'close-open',
                    'view-route',
                    'connect',
                    'merge',
                    'request',
                ],
                'all'
            );
            can('create:batchByProgram', 'Event');
            cannot('read', 'Settings');
            break;
        case UserType.ACCOUNT_MANAGER:
            can(['create', 'delete', 'update', 'close-open', 'read', 'connect', 'merge'], 'Site');
            can(['create', 'delete', 'update', 'read'], 'Settlement');
            can(['create', 'delete', 'update', 'read'], 'User');
            can(['create', 'update', 'read', 'filter'], 'Company');
            can(['enroll', 'create', 'delete', 'update', 'read'], 'PriceResponse');
            can(['enroll', 'create', 'delete', 'update', 'read'], 'CleanResponse');
            can(['create', 'update', 'filter', 'attach', 'read'], 'ControlProvider');
            can(['create', 'update', 'filter', 'attach', 'read'], 'UtilityCustomer');
            can(['create', 'delete', 'update', 'read'], 'CustomerVen');
            can('read', 'CloudVen');
            can('read', 'SanValidationSuggestion');
            can(['enroll', 'read'], 'Program');
            can('read', 'Event');
            can('read', 'EnergyCustomer');
            can('read', 'ConnectCustomer');
            can('read', 'ImpactReporting');
            can('upload', 'EnergyFile');
            can('read', 'carbon-intensity');
            can('read', 'PriceResponseReporting');
            can('read', 'PeakLoadForecast');
            can('enroll', 'LeapApi');
            can('view-route', [
                '/',
                '/sites',
                '/settlements',
                '/users',
                '/companies',
                '/trigger/price-response',
                '/trigger/clean-response',
                '/programs',
                '/events',
                '/impact',
                '/ven-devices',
                '/ven-devices/customer-ven',
                '/ven-devices/overview',
                '/ven-devices/cloud-ven',
                '/caiso',
                '/caiso/sibr',
                '/caiso/drrs',
                '/reporting/price-response',
                '/programs/sites-enrollment',
                '/energy-usage-data/summary',
                '/energy-usage-data/analytics',
                '/energy-usage-data/connect',
                '/peak-load-forecast',
                '/admin/data-quality',
                '/admin/data-quality/by-company',
                '/admin/data-quality/by-enrollment',
                '/admin/new-and-closed-sites',
                '/admin/recently-deleted-objects',
                '/admin/salesforce-products',
                '/admin/audit-trail',
            ]);
            break;
        case UserType.CUSTOMER:
            can('read', 'Site');
            can(['enroll', 'create', 'delete', 'update', 'read'], 'PriceResponse');
            can(['enroll', 'create', 'delete', 'update', 'read'], 'CleanResponse');
            can(['create', 'delete', 'update', 'read'], 'User');
            can(['read', 'attach'], 'ControlProvider');
            can(['read', 'attach'], 'UtilityCustomer');
            can('read', 'ImpactReporting');
            can('read', 'Settlement');
            can('read', 'Settings');
            can(['read'], 'Program');
            can('read', 'Event');
            if (user.user_feature_flag.includes('event:manage')) {
                can(['create', 'create:batchByProgram', 'update', 'delete'], 'Event');
            }
            can('read', 'carbon-intensity');
            can(['create', 'delete', 'update', 'read'], 'CustomerVen');
            can('view-route', [
                '/',
                '/sites',
                '/trigger/price-response',
                '/trigger/clean-response',
                '/users',
                '/settlements',
                '/events',
                '/impact',
                '/ven-devices',
                '/ven-devices/customer-ven',
                '/ven-devices/overview',
                '/reporting/price-response',
                '/programs',
                '/programs/sites-enrollment',
                '/energy-usage-data/connect',
                '/energy-usage-data/analytics',
            ]);

            if (user.user_feature_flag.includes('peak-load-forecast:view')) {
                can('read', 'PeakLoadForecast');
                can('view-route', ['/peak-load-forecast']);
            }

            break;
        case UserType.OPERATOR:
            can('read', 'Event');
            can('read', 'User');
            can('read', 'Site');
            can('read', 'ControlProvider');
            can('read', 'UtilityCustomer');
            can('read', 'carbon-intensity');
            can('view-route', ['/events', '/', '/users', '/sites', '/impact', '/energy-usage-data/connect', '/energy-usage-data/analytics']);
            can('read', 'ImpactReporting');

            if (user.user_feature_flag.includes('peak-load-forecast:view')) {
                can('read', 'PeakLoadForecast');
                can('view-route', ['/peak-load-forecast']);
            }

            break;

        case UserType.CONTROL_PROVIDER:
            can(['read'], 'User');
            can('filter', 'Company');
            can('read', 'Event');
            if (user.user_feature_flag.includes('event:manage')) {
                can(['create'], 'Event');
            }
            can('read', 'Site');
            can('read', 'Reporting');
            can('read', 'carbon-intensity');
            if (user?.user_feature_flag?.includes('reporting:impact')) {
                can('read', 'ImpactReporting');
            }
            can(['create', 'delete', 'update', 'read'], 'CustomerVen');
            can('view-route', [
                '/',
                '/users',
                '/events',
                '/sites',
                '/ven-devices',
                '/ven-devices/customer-ven',
                '/ven-devices/overview',
                '/energy-usage-data/connect',
                '/energy-usage-data/analytics',
                ...((user.user_feature_flag.includes('reporting:impact') ? ['/impact'] : []) as RouteSubject[]),
            ]);
            break;
        case UserType.UTILITY_CUSTOMER:
            can('read', 'User');
            can(['filter', 'update:branding'], 'Company');
            can('read', 'Event');
            if (user.user_feature_flag.includes('event:manage')) {
                can(['create:batchByProgram'], 'Event');
            }
            can('read', 'Site');
            can('read', 'Reporting');
            can('read', 'ImpactReporting');
            can('read', 'Program');
            can('read', 'PeakLoadForecast');

            can('view-route', [
                '/',
                '/users',
                '/events',
                '/sites',
                '/impact',
                '/programs',
                '/energy-usage-data/connect',
                '/energy-usage-data/analytics',
                '/energy-usage-data/summary',
                '/peak-load-forecast',
            ]);
            break;

        default:
            can('read', 'all');
            break;
    }

    return build({ conditionsMatcher: lambdaMatcher });
}
