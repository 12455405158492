import React, { useContext, useMemo, useState } from 'react';
import App from 'antd/lib/app';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import Badge from 'antd/lib/badge';
import Button from 'antd/lib/button';
import { ColumnsType, FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { DisconnectOutlined, LinkOutlined, DownloadOutlined } from '@ant-design/icons';

import './salesforceProducts.css';
import { useAuth } from '../../../domain/auth/useAuth';
import { AbilityContext } from '../../../components/ability/can';
import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { getColumnFilteredValue, getColumnSortOrder } from '../../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../../components/table/withEmptyDataTable';
import { useDocumentTitle } from '../../../components/useDocumentTitle';
import { defaultDateFormat } from '../../../domain/common/dateFormatters';
import { toUsefulParams } from '../../toUsefulParams';
import { usePageLocation } from '../../usePageState';
import { IProduct, SPECIFIC_PRODUCT_ACTION } from '../../../domain/product/interface';
import { compareWithLocale } from '../../../domain/compareWithLocale';
import { useProductListQuery, useProductProgramTypesListQuery } from '../../../domain/product/queries';
import { IPaginationMeta } from '../../../domain/IPagination';
import { getTablePaginationParams } from '../../../components/table/getTablePaginationParams';
import { DEFAULT_PAGINATION } from '../../../domain/commonConst';
import { ActionList } from '../../../components/table/actionList/actionList';
import { exportProducts } from '../../../domain/product';
import { SearchInput } from '../../../components/searchInput/searchInput';
import { JobName } from '../../../domain/async-job/interface';
import { SyncSalesforceButton } from '../../../components/salesforce/SyncSalesforceButton/SyncSalesforceButton';
import { UnlinkProgramFromProduct } from '../../../components/product/UnlinkProgramFromProductModal';
import { LinkProgramToProductModal } from '../../../components/product/LinkProgramToProductModal';

interface IPageState {
    filter?: {
        programTypes?: string | string[];
        isActive?: string;
        program?: string;
    };
    pagination?: {
        pageSize: number;
        current: number;
    };
    sorter?: {
        field: string;
        order: 'ascend' | 'descend';
    };
    search?: string;
}

export const defaultState: IPageState = {
    pagination: DEFAULT_PAGINATION,
    filter: {
        program: 'false',
    },
};

export const SalesforceProducts: React.FC = () => {
    const { notification } = App.useApp();
    const location = useLocation();
    const auth = useAuth()!;
    const ability = useContext(AbilityContext);

    const { queryToState, setPageQuery } = usePageLocation({ parseNumbers: false });
    const queryState = queryToState(location.search);

    const pageState: IPageState = isEmpty(queryState) ? defaultState : queryState;

    const params = toUsefulParams(pageState);

    const listQuery = {
        pagination: params?.pagination ?? DEFAULT_PAGINATION,
        ...(params?.filter && { filter: params?.filter }),
        ...(params?.sorter && { sorter: params?.sorter }),
        ...(params?.search && { search: params?.search }),
        include: 'program',
    };

    const { data, error, isError, isLoading, isFetching, refetch: refreshProductList } = useProductListQuery(listQuery);
    const products = useMemo(() => data?.data || [], [data]);
    const meta = data?.meta || ({} as IPaginationMeta);

    const { data: programTypesData } = useProductProgramTypesListQuery();
    const programTypes = programTypesData || [];

    const [isExportLoading, setIsExportLoading] = useState(false);
    const [product, setProduct] = useState<IProduct | null>(null);
    const [pageAction, setPageAction] = useState<SPECIFIC_PRODUCT_ACTION | ''>();

    useDocumentTitle('Salesforce Products');

    if (isError) {
        notification.error({
            key: 'salesforce-products-fetch-error',
            message: error?.message || 'Cannot fetch Salesforce Products!',
        });
    }

    async function onTableChange(
        pagination: TablePaginationConfig,
        filter: Record<string, FilterValue | null>,
        sorter: SorterResult<IProduct> | SorterResult<IProduct>[]
    ) {
        const preparedParams = toUsefulParams({ pagination, sorter, filter });
        setPageQuery({ ...pageState, ...preparedParams });
    }

    const onSearch = async (value: string) => {
        setPageQuery({ ...pageState, search: value });

        if (value === (pageState.search || '')) {
            listQuery.pagination.current = DEFAULT_PAGINATION.current;
        }
    };

    const exportSalesforceProductsAction = async () => {
        try {
            setIsExportLoading(true);
            await exportProducts(listQuery);

            notification.success({ message: 'CSV export was successful!' });
        } catch (err: any) {
            notification.error({
                key: 'salesforce-products-export-error',
                message: err.message || 'Cannot export Salesforce Products!',
            });
        }
        setIsExportLoading(false);
    };

    const showPageActionModal = (record: IProduct, action: SPECIFIC_PRODUCT_ACTION) => () => {
        setPageAction(action);
        setProduct(record);
    };

    async function onProductFormModalClose(isRefreshNeeded: boolean = false) {
        setPageAction('');
        setProduct(null);

        if (isRefreshNeeded) {
            await refreshProductList(listQuery);
        }
    }

    const actions = [
        ...(auth.user?.isAdmin()
            ? [
                  {
                      title: (record: IProduct) => (!record.program_id ? 'Link Program' : 'Unlink Program'),
                      onClick: (record: IProduct) =>
                          showPageActionModal(
                              record,
                              !record.program_id ? SPECIFIC_PRODUCT_ACTION.LINK_PROGRAM : SPECIFIC_PRODUCT_ACTION.UNLINK_PROGRAM
                          ),
                      icon: (record: IProduct) => (!record.program_id ? <LinkOutlined /> : <DisconnectOutlined />),
                  },
              ]
            : []),
    ];

    const columns: ColumnsType<IProduct> = [
        {
            title: 'Product Name',
            key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => compareWithLocale(a.name, b.name),
            ...getColumnSortOrder('name', pageState.sorter),
            render: (text: string, product) => product.name,
        },
        {
            title: 'Program Type',
            key: 'program_type',
            dataIndex: 'program_type',
            sorter: (a, b) => compareWithLocale(a.program_type, b.program_type),
            ...getColumnSortOrder('program_type', pageState.sorter),
            filters: [{ text: 'No Program Type', value: 'null' }, ...programTypes],
            ...getColumnFilteredValue('program_type', pageState.filter),
            filterSearch: (input, record) => !!record?.text?.toString()?.toLowerCase().includes(input.toLowerCase()),
            render: (text: string, product) => product.program_type,
        },
        {
            title: 'Start Date',
            key: 'start_date',
            dataIndex: 'start_date',
            sorter: (a, b) => new Date(a.start_date || 0).getTime() - new Date(b.start_date || 0).getTime(),
            ...getColumnSortOrder('start_date', pageState.sorter),
            render: (text: string, record) => defaultDateFormat(record.start_date, '-', 'MMM dd, yyyy'),
        },
        {
            title: 'End Date',
            key: 'end_date',
            dataIndex: 'end_date',
            sorter: (a, b) => new Date(a.end_date || 0).getTime() - new Date(b.end_date || 0).getTime(),
            ...getColumnSortOrder('end_date', pageState.sorter),
            render: (text: string, record) => defaultDateFormat(record.end_date, '-', 'MMM dd, yyyy'),
        },
        {
            title: 'Enrollment Deadline',
            key: 'enrollment_deadline',
            dataIndex: 'enrollment_deadline',
            sorter: (a, b) => compareWithLocale(a.enrollment_deadline, b.enrollment_deadline),
            ...getColumnSortOrder('enrollment_deadline', pageState.sorter),
            render: (text: string, product) => product.enrollment_deadline,
        },
        {
            title: 'Salesforce ID',
            key: 'salesforce_id',
            dataIndex: 'salesforce_id',
            sorter: (a, b) => compareWithLocale(a.salesforce_id, b.salesforce_id),
            ...getColumnSortOrder('salesforce_id', pageState.sorter),
            render: (text: string, product) => product.salesforce_id,
        },
        {
            title: 'Status',
            key: 'is_active',
            dataIndex: 'is_active',
            filterMultiple: false,
            ...getColumnFilteredValue('is_active', pageState.filter),
            filters: [
                { text: 'Active', value: 'true' },
                { text: 'Inactive', value: 'false' },
            ],
            render: (text: string, product) => {
                return product.is_active ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" />;
            },
        },
        {
            title: 'Program',
            key: 'program',
            dataIndex: 'program',
            filterMultiple: false,
            ...getColumnFilteredValue('program', pageState.filter),
            filters: [
                { text: 'Linked Program', value: 'true' },
                { text: 'Not linked Program', value: 'false' },
            ],
            render: (text: string, product) => {
                return product.program?.name || '';
            },
        },
        {
            key: 'action',
            sorter: false,
            render: (text: string, record) => <ActionList actions={actions} item={record} />,
            width: 50,
        },
    ];

    return (
        <>
            <PageHeader
                pageTitle={`Salesforce Products (${meta.total || 0})`}
                actions={[
                    <SearchInput onSearch={onSearch} defaultValue={pageState?.search} key="search-program" />,
                    <Button
                        size="large"
                        key="download-csv"
                        onClick={exportSalesforceProductsAction}
                        icon={<DownloadOutlined />}
                        loading={isExportLoading}
                    >
                        Download CSV
                    </Button>,
                    ability.can('create', 'AsyncJob') && <SyncSalesforceButton jobName={JobName.SYNC_SALESFORCE_PRODUCTS} key="salesforce-sync" />,
                ]}
            />
            <WithEmptyDataTable condition={isFetching || isLoading}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    className="salesforce-products-table"
                    rowClassName={record => (record.program_id ? 'attached-row' : '')}
                    columns={columns}
                    dataSource={products}
                    loading={isFetching || isLoading}
                    onChange={onTableChange}
                    pagination={getTablePaginationParams(data?.meta)}
                />
            </WithEmptyDataTable>

            {product && pageAction === SPECIFIC_PRODUCT_ACTION.LINK_PROGRAM && (
                <LinkProgramToProductModal product={product} onClose={onProductFormModalClose} />
            )}

            {product && pageAction === SPECIFIC_PRODUCT_ACTION.UNLINK_PROGRAM && (
                <UnlinkProgramFromProduct product={product} onClose={onProductFormModalClose} />
            )}
        </>
    );
};
