import { IProgram } from '../program/interface';

export enum SPECIFIC_PRODUCT_ACTION {
    LINK_PROGRAM = 'link_program',
    UNLINK_PROGRAM = 'unlink_program',
}

export interface IProduct {
    salesforce_id: string;
    name: string;

    utility_or_iso?: string;
    access_partner?: string;
    program_type?: string;
    start_date?: string;
    end_date?: string;
    enrollment_deadline?: string;
    other_program_rules?: string;
    description?: string;
    is_active?: boolean;
    salesforce_updated_date?: string;
    salesforce_updated_by?: string;
    date_created: string;
    date_synced?: string;

    program_id: number | null;
    program: IProgram | null;
}

export interface IProductProgramType {
    text: string;
    value: string;
}
