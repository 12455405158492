import { format } from 'date-fns';
import { API } from '../api';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { IProduct, IProductProgramType } from './interface';
import { DATE_FORMAT_CSV } from '../commonConst';
import { exportCSVData } from '../common/exportCSVData';
import { ILinkProgramToProductFormData } from '../../components/product/LinkProgramToProductModal';

export const fetchProducts = async (
    { sorter, pagination, search, include, filter }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<IProduct>> => {
    const limit = pagination?.pageSize || 10000;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;
    if (!sorter?.field || !sorter?.order) {
        sorter = {};
    }

    const path = `/salesforce/products?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...prepareProductFilters(filter),
        ...(search && { search }),
        ...(include && { include }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of products!`);
};

function prepareProductFilters(filter?: Record<string, string | string[]>): Record<string, string> {
    const filters: Record<string, string> = {};

    if (!filter) {
        return filters;
    }

    Object.entries(filter).forEach(([key, value]) => {
        if (!value) return;
        const formatedKey = getFilterKey(key);

        if (Array.isArray(value)) {
            filters[formatedKey] = value.join(',');
        } else {
            filters[formatedKey] = value;
        }
    });

    return filters;
}

function getFilterKey(key: string): string {
    switch (key) {
        case 'is_active':
            return 'isActive';
        case 'program_type':
            return 'programTypes';
        case 'program':
            return 'isProgramExist';
        default:
            return key;
    }
}

export const exportProducts = async ({ search, include, filter = {} }: IFetchPageQuery) => {
    const queryParams: Record<string, string> = {
        format: 'csv',
        ...prepareProductFilters(filter),
        ...(search && { search }),
        ...(include && { include }),
    };

    const response = await API.fetch(`/salesforce/products?${new URLSearchParams(queryParams)}`, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: string = await response.text();

    if (response.ok) {
        const filename = `salesforce-products--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const fetchProductProgramTypes = async (signal?: AbortSignal | null): Promise<IProductProgramType[]> => {
    const response = await API.fetch(`/salesforce/products/program-types`, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load list of program types!`);
};

export const linkProgram = async (productId: string, data: ILinkProgramToProductFormData): Promise<IProduct> => {
    const response = await API.fetch(`/salesforce/products/${productId}/program/link`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot link program!`);
};

export const unlinkProgram = async (productId: string): Promise<IProduct> => {
    const response = await API.fetch(`/salesforce/products/${productId}/program/unlink`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot unlink program!`);
};
