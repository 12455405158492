import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { IWeatherLocation } from 'src/domain/weather/interface';
import { calcSunriseSunsetByLocation } from '../calcSunriseSunsetByLocation';
import { add, startOfHour } from 'date-fns';
import { EChartsOption } from 'echarts';
import { IPeakEventIndex, IPeakLoad, PeakLoadForecastType } from 'src/domain/peak-load-forecast/interface';
import { isArray, isNumber, maxBy, round } from 'lodash';
import { TopLevelFormatterParams } from 'echarts/types/dist/shared';
import { EventChance, getEventChance } from '../PeakEventIndexDay';

export const CHART_DATA_COLOR = 'rgba(116, 215, 213, 1)';

export function calculateTooltipPosition(point: [number, number], contentSize: [number, number]) {
    const [tooltipWidth, tooltipHeight] = contentSize;

    return [point[0] - tooltipWidth / 2, point[1] - (tooltipHeight + 20)];
}

function makeSeriesItemElement(item: SeriesItem) {
    const [, val] = item.value;

    const name = item.name === 'TopPeakDay' ? 'Actual' : item.name;
    const formattedValue = isNumber(val) ? new Intl.NumberFormat('en-EN').format(val) : 'N/A';

    return `<div style='padding: 0 10px; display: flex; justify-content: space-between; color: #2e3838; font-size: 14px; font-weight: 400'>
    <div>
        <span style='display: inline-block; width: 20px; line-height: 14px; margin-bottom: 2px; border-bottom: 4px ${item.style} ${item.color}'></span> ${name}:
    </div>
    <div>${formattedValue}${item.uom}</div>
</div>`;
}

export type ChartAreaType = 'twilight' | 'sunrise' | 'sunset' | 'medium-event-index' | 'high-event-index' | 'none';
export type ChartSettings = {
    areas: {
        hour: number;
        type: ChartAreaType;
        color: string;
    }[];
    timeframe: [number, number];
    showSunriseSunsetArea: boolean;
};

export function makeChartSettings(
    location: IWeatherLocation,
    timeframe: [number, number],
    showSunriseSunsetArea: boolean,
    timezone: string,
    dayEventIndex: IPeakEventIndex[]
) {
    const result: ChartSettings = {
        areas: [],
        timeframe,
        showSunriseSunsetArea,
    };

    const twilightColor = 'rgba(187, 195, 195, 0.2)';
    const sunriseColor = 'rgba(247, 248, 248, 0.8)';
    const sunsetColor = 'rgba(247, 248, 248, 0.8)';
    const mediumPeakColor = 'rgba(255, 176, 77, 0.5)';
    const highPeakColor = 'rgba(255, 178, 194, 0.5)';

    const { sunrise, sunset } = calcSunriseSunsetByLocation(location, dayEventIndex[0]?.datetime);
    const sunriseTz = toZonedTime(sunrise, timezone);
    const sunsetTz = toZonedTime(sunset, timezone);
    const sunriseHourStart = sunrise ? startOfHour(sunriseTz).getHours() : 0;
    const sunsetHourStart = sunset ? startOfHour(sunsetTz).getHours() : 0;

    const eventIndexMap: Record<number, IPeakEventIndex> = {};

    dayEventIndex.forEach(item => {
        const estDateTime = toZonedTime(item.datetime, timezone);
        const hour = estDateTime.getHours();
        eventIndexMap[hour] = item;
    });

    const isAverageForecast = dayEventIndex?.[0]?.forecastType === PeakLoadForecastType.AVERAGE;

    const highestLoadMw = maxBy(dayEventIndex, 'loadMw')?.loadMw || 0;

    // const hasHighEventIndex = Object.values(eventIndexMap).some(item => item > 90);
    const hasHighEventIndex = false; // todo: fix

    let position = 0;
    for (let hour = timeframe[0]; hour <= timeframe[1]; hour++) {
        // for every hour we need to identify type: high peak, medium peak, sunset, sunrise, twilight, none

        const isSunset = sunsetHourStart === hour;
        const isSunrise = sunriseHourStart === hour;
        const isTwilight = (hour >= 0 && hour < sunriseHourStart) || (hour > sunsetHourStart && hour <= 24);

        let eventChance: EventChance = 'low';

        const eventIndexItem = eventIndexMap[hour] || null;
        if (eventIndexItem) {
            const isPeakHour = eventIndexItem.loadMw === highestLoadMw;
            if (isPeakHour) {
                eventChance = getEventChance(eventIndexItem);
            }
        }

        let type: ChartAreaType = 'none';
        let color = 'transparent';

        if (isSunrise) {
            type = 'sunrise';
            color = sunriseColor;
        }

        if (isSunset) {
            type = 'sunset';
            color = sunsetColor;
        }

        if (isTwilight) {
            type = 'twilight';
            color = twilightColor;
        }

        /** if the average forecast is used - we should not display the event chance */
        if (isAverageForecast === false) {
            /** we want to show only high or medium */
            if (eventChance === 'medium' && hasHighEventIndex === false) {
                type = 'medium-event-index';
                color = mediumPeakColor;
            }

            if (eventChance === 'high') {
                type = 'high-event-index';
                color = highPeakColor;
            }
        }

        result.areas.push({
            hour: position,
            type,
            color,
        });

        position++;
    }

    return result;
}

type MainSeriesType = [Date, number];
type AdditionalSeriesType = [Date, number, string];
type SeriesType = MainSeriesType | AdditionalSeriesType;

export function makeLineSeries(
    name: 'Actual' | 'Forecasted' | 'TopPeakDay' | 'Day Ahead',
    data: SeriesType[],
    style: { color: string; type: 'dashed' | 'solid' },
    start: Date,
    end: Date,
    timeframe: [number, number]
) {
    const series: EChartsOption['series'] = [];

    if (!data) return series;

    const seriesData = fillSeriesData(data, start, end, timeframe);

    series.push({
        name: name,
        type: 'line',
        lineStyle: {
            color: style.color,
            width: 2,
            type: style.type,
        },
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 10,
        itemStyle: {
            color: style.color,
        },
        smooth: true,
        data: seriesData,
    });

    return series;
}

export function makeChartAreasSeries(settings: ChartSettings) {
    const markAreaData: any[] = [];

    const { areas, showSunriseSunsetArea } = settings;

    const isHideSunriseSunsetAreas = (type: ChartAreaType) => showSunriseSunsetArea === false && ['sunrise', 'sunset', 'twilight'].includes(type);

    areas.forEach(it => {
        const color = isHideSunriseSunsetAreas(it.type) ? 'transparent' : it.color;

        markAreaData.push([
            {
                xAxis: it.hour,
                itemStyle: {
                    color: color,
                    borderWidth: 0,
                    borderColor: 'transparent',
                },
            },
            {
                xAxis: it.hour + 1,
            },
        ]);
    });

    const series: EChartsOption['series'] = [
        {
            xAxisIndex: 0,
            name: 'Areas',
            type: 'line',
            lineStyle: {
                color: '#6697e5',
                width: 0,
                type: 'solid',
            },
            showSymbol: false,
            markArea: { data: markAreaData },
        },
    ];

    return series;
}

type Tuple = [string, number | string] | [string, number | string, string];

export function fillSeriesData(data: SeriesType[], start: Date, end: Date, timeframe: [number, number] = [0, 23]) {
    let seriesData: Tuple[] = [];

    const seriesDataMap: Record<string, SeriesType> = {};
    data.forEach(([datetime, value, extraValue]) => {
        const tuple: SeriesType = extraValue !== undefined ? [datetime, value, extraValue] : [datetime, value];
        seriesDataMap[datetime.toISOString()] = tuple;
    });

    let cursor = start;
    while (cursor.valueOf() <= end.valueOf()) {
        const key = cursor.toISOString();
        const value = seriesDataMap[key];
        if (value) {
            const [, ...part] = value;
            seriesData.push([cursor.toISOString(), ...part]);
        } else {
            seriesData.push([cursor.toISOString(), '-']);
        }

        cursor = add(cursor, { hours: 1 });
    }

    seriesData = applyTimeframe(seriesData, timeframe);

    return seriesData;
}

export function applyTimeframe(seriesData: any[], timeframe: [number, number]) {
    /**
     * we apply -1 offset here because timeframe is [1..24]
     * but hours [0..23]
     */
    const startHour = timeframe[0] - 1;
    const endHour = timeframe[1] - 1;

    return seriesData.filter((it, index) => {
        return index >= startHour && index <= endHour;
    });
}

export function parseDateTimeComponents(datetime: string) {
    const [date, time] = datetime.split('T');
    const [year, month, day] = date.split('-');
    const [hours, minutes, secondsAndMilliseconds] = time.split(':');
    const [seconds] = secondsAndMilliseconds.split('.');

    return {
        year,
        month,
        day,
        hours,
        minutes,
        seconds,
    };
}

/**
 * Here we create a new data where we map dates from preceding years to currently selected datetime
 * additionally storing original datetime
 *
 * mapped datetime is used to properly place data on chart
 * original datetime is used for tooltips
 */
export function mapDataToOneDayRange(data: [Date, number][], dayStart: Date) {
    /** initialize map for a day with zero values */
    const oneDayHoursMap: Record<string, [number, string]> = {};
    for (let hours = 0; hours < 24; hours++) {
        const key = add(dayStart, { hours }).toISOString();
        oneDayHoursMap[key] = [0, ''];
    }

    const peakDaysDataMap: Record<string, [Date, number]> = {};
    data.forEach(([datetime, value]) => {
        const { hours } = parseDateTimeComponents(datetime.toISOString());
        peakDaysDataMap[`${hours}`] = [datetime, value];
    });

    /** map and set values */
    for (let key in oneDayHoursMap) {
        const { hours } = parseDateTimeComponents(key);
        if (peakDaysDataMap[`${hours}`]) {
            const [originalDatetime, value] = peakDaysDataMap[`${hours}`];
            oneDayHoursMap[key] = [value, originalDatetime.toISOString()];
        }
    }

    const seriesData: [Date, number, string][] = Object.entries(oneDayHoursMap).map(([mappedDate, [value, originalDatetime]]) => [
        new Date(mappedDate),
        value,
        originalDatetime,
    ]);
    // We filter out records with no load data
    return seriesData.filter(item => !!item[1]);
}

export type SeriesItem = {
    name: string;
    color: string;
    style: 'dashed' | 'solid';
    value: [string, number | string] | [string, number | string, string];
    uom: string;
};

export function renderChartTooltip(series: SeriesItem[], timezone: string, location?: IWeatherLocation, loadAtEventIndex100?: number) {
    if (!series.length) return '';

    const withoutAreasSeries = series.filter(s => s.name !== 'Areas');
    const mainSeries = withoutAreasSeries.filter(s => s.name !== 'TopPeakDay') || [];
    const additionalSeries = withoutAreasSeries.filter(s => s.name === 'TopPeakDay') || [];

    const locationElement = location
        ? `<div style='text-align: center; color: #697475; padding: 0 10px;'>${location.city}, ${location.state}</div>`
        : '';

    let loadAtEventIndex100Element = '';
    if (typeof loadAtEventIndex100 !== 'undefined') {
        loadAtEventIndex100Element = makeSeriesItemElement({
            name: 'Index = 100',
            color: '#d3e1f8',
            style: 'dashed',
            value: ['', loadAtEventIndex100],
            uom: ' MW',
        });
    }

    // all series should have the same dates - so let's take first
    const [datetime] = withoutAreasSeries[0]?.value;
    const datetimeHourEnding = add(new Date(datetime), { hours: 1 });
    const formattedDate = formatInTimeZone(datetimeHourEnding, timezone, 'MMMM do, yyyy hh:mm aa');

    const formattedDateWithTimezone = `${formattedDate} ${getShortTimezone(datetimeHourEnding, timezone)}`;
    const dateElement = `<div style='text-align: center; color: #697475; padding: 0 10px;'>${formattedDateWithTimezone}</div>`;

    return `<div style='padding: 10px 0; margin: 0;' >
        ${locationElement}
        ${dateElement}
        <div style='margin: 10px 0 10px 0; height: 1px; border-bottom: 1px solid #f0f0f0;'></div> 
        ${loadAtEventIndex100Element}
        ${mainSeries.map(makeSeriesItemElement).join('\n')}
        ${additionalSeries
            .map(series => {
                /** for additional series (top peak days) we have 3rd value - original datetime */
                const [, , originalDatetimeStr] = series.value;
                if (!originalDatetimeStr) return '';
                const originalDatetime = new Date(originalDatetimeStr!);
                const originalDatetimeHourEnding = add(new Date(originalDatetime), { hours: 1 });
                const formattedDate = formatInTimeZone(originalDatetimeHourEnding, timezone, 'MMMM do, yyyy hh:mm aa');

                const formattedDateWithTimezone = `${formattedDate} ${getShortTimezone(datetimeHourEnding, timezone)}`;
                const element = makeSeriesItemElement(series);

                return `
                    <div style='text-align: center; color: #697475; padding: 15px 10px 0;'>${formattedDateWithTimezone}</div>
                    ${element}
                `;
            })
            .join('\n')}
    </div>`;
}

export function formatChartTooltip(
    params: TopLevelFormatterParams,
    timezone: string,
    uom: string,
    location?: IWeatherLocation,
    loadAtEventIndex100?: number
) {
    if (!isArray(params)) return '';

    const items = params.filter(item => item.seriesName !== 'Areas');

    const series: SeriesItem[] = items.map(item => {
        const styleMap: Record<string, 'dashed' | 'solid'> = {
            Forecasted: 'dashed',
            'Day Ahead': 'dashed',
            RealFeel: 'solid',
            Solar: 'solid',
            Actual: 'solid',
        };

        const style = styleMap[item.seriesName!] || 'solid';

        return {
            name: item.seriesName!,
            color: item.color as string,
            value: item.value as [string, number | string] | [string, number | string, string],
            style,
            uom,
        };
    });

    return renderChartTooltip(series, timezone, location, loadAtEventIndex100);
}

type SeriesData = [Date, number][];

export function peakLoadToSeriesData(data: IPeakLoad[] | undefined): SeriesData {
    return data?.length ? data.map(it => [new Date(it.datetime), round(it.loadMw)]) : [];
}

// Here we transform IANA timezone (like America/New_York) to short format (EST/EDT)
export function getShortTimezone(date: Date, timezone: string) {
    return new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        timeZoneName: 'short',
    })
        .formatToParts(date)
        .find(part => part.type === 'timeZoneName')?.value;
}
