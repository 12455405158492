import React, { useMemo } from 'react';
import Form from 'antd/lib/form';
import Typography from 'antd/lib/typography';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import Alert from 'antd/lib/alert';
import Modal from 'antd/lib/modal/Modal';
import { IProduct } from '../../domain/product/interface';
import { useProgramListQuery } from '../../domain/program/queries';
import { linkProgram } from '../../domain/product';

interface ILinkProgramToProductProps {
    product: IProduct;
    onClose: (isRefreshNeeded?: boolean) => void;
}

export interface ILinkProgramToProductFormData {
    programId: number;
}

export const LinkProgramToProductModal: React.FC<ILinkProgramToProductProps> = ({ product, onClose }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm<ILinkProgramToProductFormData>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const { data: programsData } = useProgramListQuery({});
    const programs = useMemo(() => programsData?.data || [], [programsData?.data]);

    const handleConfirm = async (data: ILinkProgramToProductFormData) => {
        setLoading(true);
        try {
            await linkProgram(product.salesforce_id, data);

            onClose(true);
            notification.success({ key: 'link-program-success', message: 'Program linked successfully!' });
        } catch (error: any) {
            notification.error({ key: 'link-program-error', message: error.message || 'Cannot link program!' });
        }
        setLoading(false);
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            title="Link Program"
            open
            destroyOnClose
            onCancel={handleClose}
            footer={[
                <Button key="link-program-modal-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="link-program-modal-submit" type="primary" loading={loading} onClick={form.submit}>
                    Link
                </Button>,
            ]}
        >
            <Alert message={`Changes will be applied to product: ${product.name}`} type="warning" style={{ marginBottom: '8px' }} showIcon />

            <Form form={form} name="link-program-form" preserve={false} layout="vertical" onFinish={handleConfirm} style={{ marginTop: '10px' }}>
                <Form.Item name="programId" label={<Typography.Text strong> Program </Typography.Text>}>
                    <Select
                        showSearch
                        autoClearSearchValue
                        optionFilterProp="label" // to search by label
                        placeholder="Please select Program"
                        size="large"
                        options={programs.map(program => ({ label: program.name, value: program.program_id }))}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};
