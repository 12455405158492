import { add, sub } from 'date-fns';
import groupBy from 'lodash/groupBy';
import { IProgram } from '../program/interface';
import { IEvent, OptType } from './interface';
import { SEPARATOR } from '../commonConst';

export interface ICalendarEvent {
    id: number;
    resourceId: any;
    start: Date;
    end: Date;
    title: string;
    classNames?: string[];
}

const groupEvents = (events: IEvent[]) => {
    let result: IEvent[] = [];
    const groupedEvents = groupBy(events, event => {
        const eventPrograms = event?.site?.programs.length ? event?.site.programs.map((p: IProgram) => p?.name) : 'Without program';
        return `${event.event_datetime_start}${SEPARATOR}${event.event_duration}${SEPARATOR}${eventPrograms}`;
    });
    Object.keys(groupedEvents).forEach(key => result.push(groupedEvents[key][0]));
    return result;
};

export const toCalendarEvents = (events: IEvent[], calendarView: string): ICalendarEvent[] => {
    const calendarEvents: ICalendarEvent[] = [];

    if (calendarView === 'dayGridMonth') {
        const groupedEventsForMonthView = groupEvents(events);
        groupedEventsForMonthView.forEach(event => calendarEvents.push(new EventMonth(event)));
    } else {
        events.forEach(event => {
            if (event.event_pre_duration > 0) {
                const preEvent = new PreEvent(event);
                calendarEvents.push(preEvent);
            }

            if (event.event_secondary_duration > 0) {
                const secondaryEvent = new SecondaryEvent(event);
                calendarEvents.push(secondaryEvent);
            }

            if (event.event_duration > 0) {
                const normalEvent = new Event(event);
                calendarEvents.push(normalEvent);
            }

            if (event.event_post_duration > 0) {
                const postEvent = new PostEvent(event);
                calendarEvents.push(postEvent);
            }
        });
    }
    return calendarEvents;
};

class BaseEvent implements ICalendarEvent {
    id: number;
    resourceId: any;
    start: Date;
    end: Date;
    title: string;
    extendedProps?: any;
    classNames: string[] = [];

    constructor(event: IEvent) {
        this.id = event.event_id;
        this.resourceId = event.site_id;
        this.title = '';
        this.start = new Date(event.event_datetime_start);
        this.end = add(new Date(event.event_datetime_start), { minutes: event.event_duration });
        this.addClass('event');

        if (event.event_cancelled) {
            this.addClass('canceled-event');
        } else if (event.event_opt_type === OptType.OPT_OUT) {
            this.addClass('optout-event');
        }

        if (event.event_source?.includes('type=bid')) {
            this.addClass('price-response-event');
        } else if (event.event_source?.includes('type=clean_response')) {
            this.addClass('clean-response-event');
        }

        if (event.event_test) {
            this.addClass('test-event');
        }

        // if (isCoincidentPeak) {
        //     this.addClass('coincident-peak-event');
        // }
    }

    protected addClass(name: string): void {
        this.classNames.push(name);
    }

    protected generateTitle(event: IEvent) {
        let titleParts: string[] = [];

        if (event.event_test) {
            titleParts.push('TEST');
        }

        if (event.event_emergency_generator_allowed) {
            titleParts.push('EMERGENCY');
        }

        if (event.event_voluntary) {
            titleParts.push('VOLUNTARY');
        }

        if (event.event_source?.includes('type=bid')) {
            titleParts = ['PRICE RESPONSE'];
        }

        if (event.event_source?.includes('type=clean_response')) {
            titleParts = ['CLEAN RESPONSE'];
        }

        // if (isCoincidentPeak) {
        //     titleParts = ['COINCIDENT PEAK'];
        // }

        return titleParts.join(' & ');
    }
}

class Event extends BaseEvent {
    constructor(event: IEvent) {
        super(event);
        this.title = this.generateTitle(event);
        this.start = new Date(event.event_datetime_start);
        this.end = add(new Date(event.event_datetime_start), { minutes: event.event_duration });
    }
}

class PreEvent extends BaseEvent {
    constructor(event: IEvent) {
        super(event);
        this.start = sub(new Date(event.event_datetime_start), { minutes: event.event_pre_duration });
        this.end = new Date(event.event_datetime_start);
        this.addClass('pre-event');
    }
}

class PostEvent extends BaseEvent {
    constructor(event: IEvent) {
        super(event);
        this.start = add(new Date(event.event_datetime_start), { minutes: event.event_duration });
        this.end = add(this.start, { minutes: event.event_post_duration });
        this.addClass('post-event');
    }
}

class SecondaryEvent extends BaseEvent {
    constructor(event: IEvent) {
        super(event);
        this.start = sub(new Date(event.event_datetime_start), { minutes: event.event_secondary_duration });
        this.end = new Date(event.event_datetime_start);
        this.addClass('secondary-event');
    }
}

class EventMonth extends BaseEvent {
    constructor(event: IEvent) {
        super(event);
        this.extendedProps = { duration: event.event_duration };
        this.title = event?.site?.programs?.map((p: IProgram) => p.name).join(', ') || '';
    }
}
