import { ProgramUtilityAutomation } from './interface';
import orderBy from 'lodash/orderBy';

export const ProgramUtilityAutomationOptions = orderBy(
    [
        {
            value: ProgramUtilityAutomation.ENBALA,
            label: '[email] - PNM, Peak Saver',
        },
        {
            value: ProgramUtilityAutomation.EVERGY,
            label: '[email] - Evergy, BDR',
        },
        {
            value: ProgramUtilityAutomation.VOLTUS,
            label: '[email] - Voltus, PG&E DRAM',
        },
        // {
        //     value: ProgramUtilityAutomation.TNMP,
        //     label: '[email] - TNMP CLM',
        // },
        // {
        //     value: ProgramUtilityAutomation.TFCCIONCOR,
        //     label: '[email] - Oncor CLM',
        // },
        // {
        //     value: ProgramUtilityAutomation.CPOWERENERGY,
        //     label: '[email] - CPower, APS',
        // },
        // {
        //     value: ProgramUtilityAutomation.CPSENERGY,
        //     label: '[email] - CPS Demand Response',
        // },
        // {
        //     value: ProgramUtilityAutomation.AEP,
        //     label: '[email] - AEP, TX',
        // },
        // {
        //     value: ProgramUtilityAutomation.NRG_ACTIVATIONS,
        //     label: '[email] - NRG, ERS-30',
        // },
        // {
        //     value: ProgramUtilityAutomation.AUSTIN_ENERGY,
        //     label: '[email] - Austin Energy',
        // },

        // "SCE, CBP" (email:APX) and "SCE, BIP" (email:SCE) options are temporary disabled as parsers for those are not ready
        // {
        //     value: ProgramUtilityAutomation.SCE,
        //     label: '[email] - SCE, BIP',
        // },
        // {
        //     value: ProgramUtilityAutomation.APX,
        //     label: '[email] - SCE, CBP',
        // },

        // Not used now, because automated via OpenAdr, DRMS-4441
        // {
        //     value: ProgramUtilityAutomation.ROCKY_MOUNTAIN_ENEL,
        //     label: '[email] - Rocky Mountain Power, Enel',
        // },
        // {
        //     value: ProgramUtilityAutomation.EVERBRIDGE,
        //     label: '[email] - SRP, Demand Response',
        // },
        // {
        //     value: ProgramUtilityAutomation.PUGET_SOUND_ENEL,
        //     label: '[email] - Puget Sound, Enel',
        // },
    ],
    'label'
);
